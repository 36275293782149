import React, {useEffect} from 'react'
import WorkDetailContent from './WorkDetailContent'

const WorkDetailModal = ({
    workDetailData,
    onClickedCloseButton
  }) => {

  useEffect( () => {
    //fix background scroll positon while this component is mounted
    document.body.style.overflow = 'hidden'
    return () => document.body.style.overflow = 'unset'
  }, [] )

  return (
    <div className="modal__background" onClick={onClickedCloseButton}>
      <div className="modal__close-button-container">
        <div className="modal__close-button-wrapper">
          <svg className="modal__close-button js-close-modal" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M1232.46863,16.4686292 C1233.09347,15.8437903 1234.10653,15.8437903 1234.73137,16.4686292 L1234.73137,16.4686292 L1244,25.737 L1253.26863,16.4686292 C1253.89347,15.8437903 1254.90653,15.8437903 1255.53137,16.4686292 C1256.15621,17.093468 1256.15621,18.106532 1255.53137,18.7313708 L1246.263,28 L1255.53137,37.2686292 C1256.11945,37.8567128 1256.15405,38.7886913 1255.63515,39.4172332 L1255.53137,39.5313708 C1254.90653,40.1562097 1253.89347,40.1562097 1253.26863,39.5313708 L1253.26863,39.5313708 L1244,30.263 L1234.73137,39.5313708 C1234.10653,40.1562097 1233.09347,40.1562097 1232.46863,39.5313708 C1231.84379,38.906532 1231.84379,37.893468 1232.46863,37.2686292 L1241.737,28 L1232.46863,18.7313708 C1231.88055,18.1432872 1231.84595,17.2113087 1232.36485,16.5827668 Z" transform="translate(-1232 -16)"/>
          </svg>
        </div>
      </div>
      <div className="modal__body-container">
        <WorkDetailContent
          workDetailData={workDetailData}
        />
      </div>
    </div>
  )
}

export default WorkDetailModal